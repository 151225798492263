<template>
  <VueFinalModal name="tours_list" v-bind="$attrs" classes="modal-container" content-class="modal-content" @before-open="onBeforeOpen">
    <div class="modal-ref modal-ref--short">
      <div class="modal-ref__header">
        <div class="modal-ref__title">{{ $t("modal.tour2_list.title") }}</div>
        <button @click="$vfm.hide('tours_list')" type="button" class="modal-ref__close">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.501001 17.4293C0.0387289 16.9643 0.0387289 16.2133 0.501001 15.7482L15.6786 0.570598C15.97 0.230352 16.4275 0.0821452 16.8631 0.186912C17.2986 0.291679 17.6386 0.631738 17.7434 1.06728C17.8482 1.50281 17.7 1.96032 17.3597 2.2517L2.1821 17.4293C1.71707 17.8916 0.966032 17.8916 0.501001 17.4293Z"
              fill="#B9C0D3"
            />
            <path
              d="M0.501001 0.570581C0.966032 0.108309 1.71707 0.108309 2.1821 0.570581L17.3597 15.7482C17.7 16.0396 17.8482 16.4971 17.7434 16.9326C17.6386 17.3682 17.2986 17.7082 16.8631 17.813C16.4275 17.9178 15.97 17.7696 15.6786 17.4293L0.501001 2.25168C0.0387289 1.78665 0.0387289 1.03561 0.501001 0.570581Z"
              fill="#B9C0D3"
            />
          </svg>
        </button>
      </div>
      <div class="modal-ref__list">
        <div class="modal-ref__list-item" v-for="item in list" :key="`history-${item.id}`">
          <div class="modal-ref__list-item-title">
            {{ $t("modal.tour2_list.bank") }}
            {{
              Object.values(item.award.place)
                .reduce((a, b) => (a += b), 0)
                .toLocaleString()
            }}
            <img src="/images/coin.png" class="modal-ref__stats-item-coin" alt="" />
          </div>
          <div class="modal-ref__list-item-subtitle">
            {{ item.finished_at.split(" ")[0] }}
          </div>
          <button type="button" class="modal-ref__list-item-button" @click="$vfm.show('tours_details', { id: item.id })">{{ $t("modal.tour2_list.show") }}</button>
        </div>
      </div>
      <VPagination @prev="updatePage(-1)" @next="updatePage(1)" :last="meta.last_page" :current="meta.current_page" />

      <ModalToursDetails v-model="modal" />
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal, $vfm } from "vue-final-modal";

const { $axios } = useNuxtApp();

const modal = ref(false);
const ModalToursDetails = defineAsyncComponent(() => import("@/components/Modal/ModalToursDetails.vue"));

const list = ref([]);
const meta = ref({
  current_page: 1,
  last_page: 1,
});

const fetchItems = async () => {
  await $axios
    .$get("bonus/tournaments/history", {
      params: {
        page: meta.value.current_page,
      },
    })
    .then(({ response }) => {
      if (response.data) {
        Object.assign(meta.value, response.meta);
        list.value = response.data;
      }
    });
};

const onBeforeOpen = async () => {
  if (!list.value.length || meta.value.current_page !== 1) {
    meta.value.current_page = 1;
    await fetchItems();
  }
};

const updatePage = (num) => {
  if (meta.value.current_page + num < 1 || meta.value.current_page + num > meta.value.last_page) return;
  meta.value.current_page += num;
  fetchItems();
};
</script>
