<template>
  <div class="tour__left">
    <div
      class="tour__left-row"
      v-for="(position, idx) in top"
      :key="`pos-${idx}`"
      :class="{
        'tour__left-row--active': position.user && $auth.loggedIn && position.user.id === $auth.user.id,
        last: position.user && $auth.loggedIn && position.user.id === $auth.user.id && position.place > 10,
      }"
    >
      <div v-if="position.place <= 3" class="tour__left-row-chevron tour__left-row-chevron--white">
        <svg width="49" height="76" viewBox="0 0 49 76" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 0H49V73.1937C49 74.8049 47.1923 75.7547 45.8654 74.8407L25.6346 60.9046C24.9514 60.434 24.0486 60.434 23.3654 60.9046L3.13457 74.8407C1.80772 75.7547 0 74.8049 0 73.1937V0Z"
            :fill="`url(#paint${position.place})`"
          />
          <defs>
            <linearGradient id="paint1" x1="24.5" y1="0" x2="24.5" y2="77" gradientUnits="userSpaceOnUse">
              <stop stop-color="#589925" />
              <stop offset="1" stop-color="#9EDE6C" />
            </linearGradient>
            <linearGradient id="paint2" x1="24.5" y1="0" x2="24.5" y2="77" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFC658" />
              <stop offset="1" stop-color="#F4A100" />
            </linearGradient>
            <linearGradient id="paint3" x1="24.5" y1="0" x2="24.5" y2="77" gradientUnits="userSpaceOnUse">
              <stop stop-color="#69A4FF" />
              <stop offset="1" stop-color="#7449E6" />
            </linearGradient>
          </defs>
        </svg>

        <div class="tour__left-row-chevron-content">
          {{ position.place }}
        </div>
      </div>
      <div v-else class="tour__left-row-position">{{ position.place }}</div>
      <div>
        <div class="tour__left-row-label">{{ $t("modal.tour2_details.nick") }}</div>
        <div class="tour__left-row-text">
          <button type="button" class="tour__left-row-copy" v-if="mids.includes($auth.user?.id)" @click="copy(position.user.id)"><FontIcon icon="copy" size="16" /></button>{{ position.user.name }}
        </div>
      </div>
      <div class="tour__left-row-points">
        <div class="tour__left-row-label">{{ $t("modal.tour2_details.points") }}</div>
        <div class="tour__left-row-text">{{ parseFloat(position.total).toLocaleString() }}</div>
      </div>
      <div class="tour__left-row-gain">
        <div class="tour__left-row-label">{{ $t("modal.tour2_details.gain") }}</div>
        <div class="tour__left-row-text">
          {{ getBank(position).toLocaleString() }}
          <img src="/images/coin.png" class="tour__left-row-text-icon" alt="" />
        </div>
      </div>
    </div>
    <div v-if="userPoints?.place && userPoints.place > 10" class="tour__left-row tour__left-row--active last">
      <div class="tour__left-row-position">{{ userPoints.place }}</div>
      <div>
        <div class="tour__left-row-label">{{ $t("modal.tour2_details.nick") }}</div>
        <div class="tour__left-row-text">{{ userPoints.name }}</div>
      </div>
      <div>
        <div class="tour__left-row-label">{{ $t("modal.tour2_details.points") }}</div>
        <div class="tour__left-row-text">{{ parseFloat(userPoints.total).toLocaleString() }}</div>
      </div>
      <div>
        <div class="tour__left-row-label">{{ $t("modal.tour2_details.gain") }}</div>
        <div class="tour__left-row-text">
          {{ getBank(userPoints).toLocaleString() }}
          <img src="/images/coin.png" class="tour__left-row-text-icon" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import copier from "clipboard-copy";

const props = defineProps(["top", "award"]);
const { $auth, $showToast } = useNuxtApp();
const mids = [911454, 1036799, 996836, 1108665];
const getBank = (row) => {
  return props.award[row.place] || props.award?.place?.[row.place] || 0;
};

const copy = (id) => {
  copier(id);
  $showToast("ID скопирован", "success");
};
</script>

<style lang="scss">
.tour__left-row-points {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  @media screen and (max-width: 400px) {
    position: relative;
    left: unset;
    top: unset;
    transform: none;
  }
}
.tour__left-row-label {
  text-align: left;
}
.tour__left-row-gain {
  margin-left: auto;

  .tour__left-row-label {
    text-align: right;
  }
}
.tour__left-row-position {
  // @media screen and (max-width: 500px) {
  //   overflow: hidden;
  //   max-width: 40px;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  //   display: flex;
  // }
}
.tour__left-row-copy {
  margin-right: 8px;
  padding: 2px;
  background: var(--bg_secondary__100);

  svg {
    width: 16px;
    height: 16px;
  }
}
</style>
