<template>
  <div class="tours2">
    <div class="tour__banner tours2__banner">
      <img v-if="false" src="/images/tour-left.png" class="tour__banner-image" alt="" />
      <div v-if="false" class="tour__banner-content">
        <div class="tour__banner-content-title">{{ $t("page.tournaments.subtitle") }}</div>
        <div class="tour__banner-content-subtitle">
          {{ $t("page.tournaments.gain") }} {{ bank.toLocaleString() }}
          <img src="/images/coin.png" class="tour__banner-content-coin" alt="" />
        </div>
      </div>

      <div class="tour-video">
        <video autoplay muted loop playsinline>
          <source src="@/assets/video/tour4.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="animate_amount">
        <img src="@/assets/img/100000.png" style="width: 581px" class="tour__banner-amount" alt="" />
      </div>
      <div class="tour__banner-title">{{ $t("page.tournaments.subtitle") }}</div>
      <div class="tour__banner-timer-container">
        <div class="tour__banner-timer">
          <svg class="tour__banner-timer-icon" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.1181 27.4232C10.8661 27.9955 11.6875 28.4768 12.5657 28.8504L11.1789 31.2524C10.7877 31.93 9.92131 32.1621 9.24373 31.7709C8.56614 31.3797 8.33399 30.5133 8.72519 29.8357L10.1181 27.4232ZM21.4422 28.8457C22.32 28.4712 23.1409 27.9891 23.8884 27.416L25.2606 29.7927C25.6518 30.4703 25.4196 31.3367 24.7421 31.7279C24.0645 32.1191 23.1981 31.887 22.8069 31.2094L21.4422 28.8457Z"
              fill="#7177FF"
            />
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.0001 29.75C10.7409 29.75 5.66678 24.6759 5.66678 18.4167C5.66678 12.1575 10.7409 7.08336 17.0001 7.08336C23.2593 7.08336 28.3335 12.1575 28.3335 18.4167C28.3335 24.6759 23.2593 29.75 17.0001 29.75ZM27.0143 4.60997L29.4925 7.08817C30.3223 7.91804 30.3223 9.26351 29.4925 10.0934C28.6626 10.9232 27.3171 10.9232 26.4873 10.0934L24.0091 7.61517C23.1792 6.78531 23.1792 5.43983 24.0091 4.60997C24.8389 3.7801 26.1844 3.7801 27.0143 4.60997ZM7.50651 4.08297C8.33637 3.25311 9.68185 3.25311 10.5117 4.08297C11.3416 4.91283 11.3416 6.25831 10.5117 7.08817L7.50651 10.0934C6.67664 10.9232 5.33117 10.9232 4.5013 10.0934C3.67144 9.26351 3.67144 7.91804 4.5013 7.08817L7.50651 4.08297Z"
              fill="#7177FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.9465 10.625H17.0662C17.4301 10.625 17.7348 10.9008 17.771 11.2629L18.4155 17.7083L23.017 20.3377C23.2377 20.4638 23.3739 20.6985 23.3739 20.9527V21.25C23.3739 21.5488 23.1317 21.791 22.8329 21.791C22.7848 21.791 22.7369 21.7846 22.6905 21.772L16.147 19.9874C15.8192 19.898 15.601 19.5884 15.6271 19.2497L16.2402 11.279C16.2686 10.91 16.5764 10.625 16.9465 10.625Z"
              fill="#7177FF"
            />
          </svg>

          <div>
            <div class="tour__banner-timer-title">{{ $t("page.tournaments.remain") }}</div>
            <div class="tour__banner-timer-subtitle">{{ timeout }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="top.length" class="tours2__favorite">
      <div v-for="(row, idx) in top.slice(0, 3)" :key="`t-f-${idx}`" class="tours2__favorite-item">
        <div
          class="tours2__favorite-item-bg"
          :class="{
            'tours2__favorite-item-bg-1': idx + 1 === 1,
            'tours2__favorite-item-bg-2': idx + 1 === 2,
            'tours2__favorite-item-bg-3': idx + 1 === 3,
          }"
        ></div>
        <img src="@/assets/img/page/tournaments/cup-bg.svg?url" class="tours2__favorite-item-cup" alt="" />

        <TourPlace class="tours2__favorite-item-image" :place="idx + 1" />
        <div class="tours2__favorite-item-grid">
          <div class="tours2__favorite-item-block">
            <div class="tours2__favorite-item-block-label">{{ $t("page.tournaments.t_nick") }}</div>
            <div class="tours2__favorite-item-block-text">{{ row.user.name }}</div>
          </div>
          <div class="tours2__favorite-item-block">
            <div class="tours2__favorite-item-block-label">{{ $t("page.tournaments.t_points") }}</div>
            <div class="tours2__favorite-item-block-text">{{ parseFloat(row.total).toLocaleString() }}</div>
          </div>
          <div class="tours2__favorite-item-block">
            <div class="tours2__favorite-item-block-place">
              <svg v-if="idx + 1 === 1" width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0273 4.8H26.6759C26.8586 4.79667 27.0396 4.83572 27.2047 4.91408C27.3698 4.99243 27.5145 5.10798 27.6274 5.25164C27.7403 5.39531 27.8184 5.56316 27.8556 5.74208C27.8928 5.921 27.888 6.10611 27.8416 6.28287L26.7702 10.4314C26.5303 11.3702 25.9849 12.2024 25.2197 12.7968C24.4546 13.3912 23.5134 13.714 22.5445 13.7143H17.873"
                  stroke="url(#paint0_linear_3431_10338)"
                  stroke-width="1.17"
                  stroke-miterlimit="10"
                />
                <path
                  d="M7.32922 4.80005H1.68065C1.5001 4.80069 1.32209 4.84264 1.16026 4.9227C0.99844 5.00277 0.857088 5.11884 0.747046 5.26197C0.637005 5.40511 0.56119 5.57152 0.52541 5.74848C0.489631 5.92545 0.494842 6.10827 0.540634 6.28292L1.61206 10.4315C1.85191 11.3702 2.39742 12.2024 3.16255 12.7968C3.92768 13.3912 4.86888 13.714 5.83777 13.7143H10.5092"
                  stroke="url(#paint1_linear_3431_10338)"
                  stroke-width="1.17"
                  stroke-miterlimit="10"
                />
                <path d="M14.1781 19.9715H13.0639C13.4924 19.3115 13.1153 18.2143 12.3867 17.4772H14.1781V19.9715Z" fill="url(#paint2_linear_3431_10338)" />
                <path d="M14.1777 19.9715H15.292C14.8634 19.3115 15.2406 18.2143 15.9692 17.4772H14.1777V19.9715Z" fill="url(#paint3_linear_3431_10338)" />
                <path d="M14.1781 19.9714H13.0639C13.4924 20.6314 13.1153 21.7286 12.3867 22.4657H14.1781V19.9714Z" fill="url(#paint4_linear_3431_10338)" />
                <path d="M14.1777 19.9714H15.292C14.8634 20.6314 15.2406 21.7286 15.9692 22.4657H14.1777V19.9714Z" fill="url(#paint5_linear_3431_10338)" />
                <path d="M15.9696 22.4657H12.3867V24.9857H15.9696V22.4657Z" fill="url(#paint6_linear_3431_10338)" />
                <path
                  d="M14.8206 19.9715C14.7054 19.5482 14.6865 19.1044 14.7651 18.6728C14.8437 18.2412 15.0179 17.8327 15.2749 17.4772H12.832C13.4492 19.0828 13.4492 20.8602 12.832 22.4657V24.9943H15.2749V22.4829C15.0153 22.1253 14.8397 21.7138 14.761 21.279C14.6824 20.8442 14.7027 20.3973 14.8206 19.9715Z"
                  fill="url(#paint7_linear_3431_10338)"
                />
                <path
                  d="M19.2692 27.6257H9.12923C9.08993 27.6244 9.05168 27.6126 9.01838 27.5917C8.98507 27.5708 8.9579 27.5414 8.93961 27.5066C8.92133 27.4718 8.91258 27.4327 8.91427 27.3934C8.91596 27.3542 8.92801 27.316 8.94921 27.2829L9.41208 26.5714C10.1136 25.5061 11.1516 24.7065 12.3607 24.3C13.5415 23.8971 14.8227 23.8971 16.0035 24.3C17.21 24.707 18.2452 25.5067 18.9435 26.5714L19.4064 27.2829C19.4268 27.313 19.4396 27.3478 19.4435 27.384C19.4474 27.4202 19.4424 27.4569 19.4288 27.4907C19.4153 27.5246 19.3937 27.5546 19.3659 27.5781C19.338 27.6016 19.3048 27.618 19.2692 27.6257Z"
                  fill="url(#paint8_linear_3431_10338)"
                />
                <path
                  d="M18.1548 27.4029H9.97766C9.9421 27.3982 9.90826 27.3848 9.87926 27.3637C9.85025 27.3426 9.82699 27.3145 9.81164 27.2821C9.79628 27.2497 9.78932 27.214 9.79137 27.1781C9.79341 27.1423 9.80443 27.1076 9.82338 27.0772L10.2005 26.3829C10.7215 25.4033 11.5625 24.6318 12.5834 24.1972C13.5314 23.8117 14.5925 23.8117 15.5405 24.1972C16.5641 24.6362 17.4055 25.414 17.9234 26.4L18.3005 27.0943C18.4119 27.2143 18.292 27.4029 18.1548 27.4029Z"
                  fill="url(#paint9_linear_3431_10338)"
                />
                <path
                  d="M14.1261 23.9143C13.3702 24.1683 12.678 24.5824 12.0967 25.1282C11.5154 25.674 11.0586 26.3388 10.7575 27.0771C10.689 27.2143 10.7575 27.4029 10.8861 27.4029H12.4118C12.309 27.4029 12.0004 25.6629 14.1261 23.9143Z"
                  fill="url(#paint10_linear_3431_10338)"
                />
                <path
                  d="M13.9887 19.9715C13.9346 19.1387 13.969 18.3026 14.0915 17.4772H13.543C13.6801 19.1372 13.6801 20.8057 13.543 22.4658V24.18L14.0915 23.9057V22.4829C13.9678 21.6518 13.9333 20.8099 13.9887 19.9715Z"
                  fill="url(#paint11_linear_3431_10338)"
                />
                <path
                  d="M12.4121 17.4772C12.8332 17.8798 13.1305 18.3942 13.2693 18.96C13.467 19.0107 13.6673 19.0508 13.8693 19.08H14.6064C14.8084 19.0508 15.0087 19.0107 15.2064 18.96C15.3451 18.3942 15.6425 17.8798 16.0635 17.4772H12.4121Z"
                  fill="url(#paint12_linear_3431_10338)"
                />
                <path
                  d="M23.1254 1.76576C23.0915 1.43915 22.9379 1.13668 22.6941 0.91667C22.4504 0.696658 22.1338 0.574705 21.8054 0.574341H6.54828C6.22047 0.576592 5.90489 0.699188 5.66153 0.918841C5.41817 1.13849 5.26399 1.43988 5.22828 1.76576C3.96828 12.96 10.3711 17.4429 13.7997 17.9401H14.5369C17.9654 17.4429 24.4111 12.96 23.1254 1.76576Z"
                  fill="url(#paint13_linear_3431_10338)"
                />
                <path
                  d="M20.7771 1.76576C20.7744 1.46793 20.6617 1.18162 20.4608 0.96174C20.2599 0.741858 19.9849 0.603901 19.6885 0.574341H7.14854C6.85144 0.602047 6.57535 0.73944 6.37408 0.959726C6.17282 1.18001 6.06082 1.46737 6.05998 1.76576C5.03141 12.96 10.3457 17.4429 13.1143 17.9401H13.7228C16.5343 17.4429 21.84 12.96 20.7771 1.76576Z"
                  fill="url(#paint14_linear_3431_10338)"
                />
                <path
                  d="M10.6981 1.63719C10.7409 1.03719 11.0924 0.574341 11.5038 0.574341H8.17807C7.76664 0.574341 7.42378 1.03719 7.37235 1.63719C6.74664 9.85719 9.27521 14.0143 11.3409 15.4715C11.4335 15.5425 11.5462 15.5821 11.6629 15.5847C11.7795 15.5872 11.8939 15.5525 11.9894 15.4856C12.085 15.4187 12.1567 15.3231 12.1942 15.2127C12.2318 15.1023 12.2331 14.9827 12.1981 14.8715C10.8193 10.6045 10.3093 6.10452 10.6981 1.63719Z"
                  fill="url(#paint15_linear_3431_10338)"
                />
                <path
                  d="M22.2097 0.574341H6.14681C5.79994 0.576476 5.46595 0.706014 5.20835 0.938328C4.95075 1.17064 4.7875 1.48953 4.74967 1.83434C4.74967 2.04006 4.7068 2.24577 4.68966 2.45149C4.68356 2.51199 4.69041 2.57309 4.70975 2.63075C4.72909 2.6884 4.76048 2.7413 4.80183 2.78589C4.84317 2.83048 4.89353 2.86574 4.94957 2.88937C5.0056 2.913 5.06602 2.92443 5.12681 2.9229H23.2297C23.2904 2.92301 23.3506 2.91069 23.4064 2.8867C23.4622 2.86271 23.5125 2.82756 23.5543 2.7834C23.596 2.73925 23.6283 2.68704 23.6491 2.62996C23.67 2.57288 23.6789 2.51214 23.6754 2.45149C23.6754 2.24577 23.6325 2.04006 23.6068 1.83434C23.569 1.48953 23.4057 1.17064 23.1481 0.938328C22.8905 0.706014 22.5565 0.576476 22.2097 0.574341Z"
                  fill="url(#paint16_linear_3431_10338)"
                />
                <path
                  d="M19.1915 0.574341H6.41151C6.10399 0.610365 5.82072 0.759122 5.61649 0.991847C5.41227 1.22457 5.30157 1.52474 5.30579 1.83434L5.25436 2.45149C5.24464 2.50574 5.24616 2.56141 5.25881 2.61505C5.27146 2.6687 5.29498 2.71918 5.32792 2.76337C5.36086 2.80756 5.40252 2.8445 5.45031 2.87195C5.49811 2.89939 5.55103 2.91673 5.6058 2.9229H20.0315C20.0861 2.91688 20.1387 2.89955 20.1862 2.872C20.2336 2.84445 20.2748 2.80729 20.3071 2.76292C20.3393 2.71855 20.362 2.66792 20.3736 2.61429C20.3852 2.56067 20.3854 2.50522 20.3744 2.45149C20.3744 2.24577 20.3744 2.04006 20.3315 1.83434C20.3329 1.51996 20.2168 1.2164 20.0059 0.983268C19.7949 0.750138 19.5045 0.604279 19.1915 0.574341Z"
                  fill="url(#paint17_linear_3431_10338)"
                />
                <path
                  d="M6.87401 1.63719C6.83973 2.0829 6.81402 2.51148 6.79688 2.9229H10.1311C10.1311 2.51148 10.1312 2.06576 10.1997 1.63719C10.1997 1.03719 10.5854 0.574341 10.9969 0.574341H7.67115C7.26829 0.574341 6.91687 1.05433 6.87401 1.63719Z"
                  fill="url(#paint18_linear_3431_10338)"
                />
                <path d="M5.0918 3.51435H23.2632C23.2632 3.30007 23.2632 3.08577 23.2632 2.86292H5.12609C5.10895 3.08577 5.10037 3.30007 5.0918 3.51435Z" fill="url(#paint19_linear_3431_10338)" />
                <path
                  d="M14.1773 13.1572C16.3313 13.1572 18.0773 11.4111 18.0773 9.25718C18.0773 7.10327 16.3313 5.35718 14.1773 5.35718C12.0234 5.35718 10.2773 7.10327 10.2773 9.25718C10.2773 11.4111 12.0234 13.1572 14.1773 13.1572Z"
                  fill="url(#paint20_linear_3431_10338)"
                />
                <path
                  d="M14.3915 7.05428L14.8887 8.05715C14.9057 8.09262 14.9308 8.12352 14.9621 8.14736C14.9934 8.17121 15.0299 8.18728 15.0686 8.19427L16.1744 8.34857C16.2187 8.3554 16.2603 8.37454 16.2944 8.40382C16.3284 8.43309 16.3535 8.47132 16.3669 8.51418C16.3803 8.55703 16.3814 8.6028 16.3701 8.64625C16.3588 8.6897 16.3355 8.7291 16.3029 8.75998L15.5058 9.53999C15.4763 9.56449 15.4543 9.59674 15.4422 9.63308C15.4301 9.66943 15.4284 9.70844 15.4372 9.74572L15.6258 10.8514C15.6352 10.8953 15.6317 10.941 15.6156 10.9829C15.5996 11.0248 15.5717 11.0611 15.5354 11.0875C15.4991 11.1138 15.4558 11.129 15.411 11.1312C15.3662 11.1335 15.3217 11.1226 15.2829 11.1L14.2886 10.5857C14.2554 10.5643 14.2167 10.5529 14.1772 10.5529C14.1377 10.5529 14.099 10.5643 14.0658 10.5857L13.0715 11.1C13.0327 11.1226 12.9882 11.1335 12.9434 11.1312C12.8986 11.129 12.8554 11.1138 12.8191 11.0875C12.7827 11.0611 12.7548 11.0248 12.7388 10.9829C12.7227 10.941 12.7192 10.8953 12.7286 10.8514L12.9172 9.74572C12.9261 9.70844 12.9244 9.66943 12.9122 9.63308C12.9001 9.59674 12.8781 9.56449 12.8487 9.53999L12.0515 8.75998C12.0189 8.7291 11.9956 8.6897 11.9843 8.64625C11.973 8.6028 11.9741 8.55703 11.9875 8.51418C12.0009 8.47132 12.026 8.43309 12.0601 8.40382C12.0941 8.37454 12.1357 8.3554 12.1801 8.34857L13.2944 8.19427C13.332 8.18738 13.3674 8.17128 13.3974 8.14734C13.4273 8.1234 13.4508 8.09241 13.4658 8.05715L13.9629 7.05428C13.9829 7.01461 14.0136 6.98128 14.0514 6.95799C14.0892 6.9347 14.1328 6.92236 14.1772 6.92236C14.2216 6.92236 14.2652 6.9347 14.303 6.95799C14.3409 6.98128 14.3715 7.01461 14.3915 7.05428Z"
                  fill="white"
                />
                <defs>
                  <linearGradient id="paint0_linear_3431_10338" x1="16.0535" y1="9.25736" x2="29.8818" y2="9.25733" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_3431_10338" x1="-1.31755" y1="9.25751" x2="12.5107" y2="9.25748" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_3431_10338" x1="12.061" y1="18.7244" x2="14.5364" y2="18.7244" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint3_linear_3431_10338" x1="13.852" y1="18.7244" x2="16.3274" y2="18.7244" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint4_linear_3431_10338" x1="12.061" y1="21.2187" x2="14.5364" y2="21.2187" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint5_linear_3431_10338" x1="13.852" y1="21.2187" x2="16.3274" y2="21.2187" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint6_linear_3431_10338" x1="11.7353" y1="23.7258" x2="16.6861" y2="23.7258" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint7_linear_3431_10338" x1="12.3879" y1="21.236" x2="15.7635" y2="21.236" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint8_linear_3431_10338" x1="6.99939" y1="25.8119" x2="21.5509" y2="25.8118" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint9_linear_3431_10338" x1="8.23586" y1="25.6556" x2="20.0551" y2="25.6555" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint10_linear_3431_10338" x1="10.1131" y1="25.6587" x2="14.8052" y2="25.6587" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint11_linear_3431_10338" x1="13.4432" y1="20.8288" x2="14.2012" y2="20.8288" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint12_linear_3431_10338" x1="11.7482" y1="18.2787" x2="16.7938" y2="18.2786" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint13_linear_3431_10338" x1="1.75256" y1="9.25782" x2="26.9378" y2="9.25777" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint14_linear_3431_10338" x1="3.20494" y1="9.25782" x2="23.9131" y2="9.25779" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint15_linear_3431_10338" x1="6.37575" y1="8.08011" x2="13.213" y2="8.0801" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint16_linear_3431_10338" x1="1.23502" y1="1.74877" x2="27.4739" y2="1.74837" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint17_linear_3431_10338" x1="2.49633" y1="1.74871" x2="23.4094" y2="1.74845" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint18_linear_3431_10338" x1="6.03324" y1="1.74871" x2="11.8369" y2="1.74869" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint19_linear_3431_10338" x1="1.7879" y1="3.18866" x2="26.8975" y2="3.18733" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                  <linearGradient id="paint20_linear_3431_10338" x1="8.85916" y1="9.25746" x2="19.6373" y2="9.25744" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC658" />
                    <stop offset="0.487457" stop-color="#F4A100" />
                    <stop offset="1" stop-color="#FFC658" />
                  </linearGradient>
                </defs>
              </svg>
              <svg v-else-if="idx + 1 === 2" width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0273 4.8H26.6759C26.8586 4.79667 27.0396 4.83572 27.2047 4.91408C27.3698 4.99243 27.5145 5.10798 27.6274 5.25164C27.7403 5.39531 27.8184 5.56316 27.8556 5.74208C27.8928 5.921 27.888 6.10611 27.8416 6.28287L26.7702 10.4314C26.5303 11.3702 25.9849 12.2024 25.2197 12.7968C24.4546 13.3912 23.5134 13.714 22.5445 13.7143H17.873"
                  stroke="url(#paint0_linear_3431_10433)"
                  stroke-width="1.17"
                  stroke-miterlimit="10"
                />
                <path
                  d="M7.32922 4.80005H1.68065C1.5001 4.80069 1.32209 4.84264 1.16026 4.9227C0.99844 5.00277 0.857088 5.11884 0.747046 5.26197C0.637005 5.40511 0.56119 5.57152 0.52541 5.74848C0.489631 5.92545 0.494842 6.10827 0.540634 6.28292L1.61206 10.4315C1.85191 11.3702 2.39742 12.2024 3.16255 12.7968C3.92768 13.3912 4.86888 13.714 5.83777 13.7143H10.5092"
                  stroke="url(#paint1_linear_3431_10433)"
                  stroke-width="1.17"
                  stroke-miterlimit="10"
                />
                <path d="M14.1781 19.9715H13.0639C13.4924 19.3115 13.1153 18.2143 12.3867 17.4772H14.1781V19.9715Z" fill="url(#paint2_linear_3431_10433)" />
                <path d="M14.1777 19.9715H15.292C14.8634 19.3115 15.2406 18.2143 15.9692 17.4772H14.1777V19.9715Z" fill="url(#paint3_linear_3431_10433)" />
                <path d="M14.1781 19.9714H13.0639C13.4924 20.6314 13.1153 21.7286 12.3867 22.4657H14.1781V19.9714Z" fill="url(#paint4_linear_3431_10433)" />
                <path d="M14.1777 19.9714H15.292C14.8634 20.6314 15.2406 21.7286 15.9692 22.4657H14.1777V19.9714Z" fill="url(#paint5_linear_3431_10433)" />
                <path d="M15.9696 22.4657H12.3867V24.9857H15.9696V22.4657Z" fill="url(#paint6_linear_3431_10433)" />
                <path
                  d="M14.8206 19.9715C14.7054 19.5482 14.6865 19.1044 14.7651 18.6728C14.8437 18.2412 15.0179 17.8327 15.2749 17.4772H12.832C13.4492 19.0828 13.4492 20.8602 12.832 22.4657V24.9943H15.2749V22.4829C15.0153 22.1253 14.8397 21.7138 14.761 21.279C14.6824 20.8442 14.7027 20.3973 14.8206 19.9715Z"
                  fill="url(#paint7_linear_3431_10433)"
                />
                <path
                  d="M19.2692 27.6257H9.12923C9.08993 27.6244 9.05168 27.6126 9.01838 27.5917C8.98507 27.5708 8.9579 27.5414 8.93961 27.5066C8.92133 27.4718 8.91258 27.4327 8.91427 27.3934C8.91596 27.3542 8.92801 27.316 8.94921 27.2829L9.41208 26.5714C10.1136 25.5061 11.1516 24.7065 12.3607 24.3C13.5415 23.8971 14.8227 23.8971 16.0035 24.3C17.21 24.707 18.2452 25.5067 18.9435 26.5714L19.4064 27.2829C19.4268 27.313 19.4396 27.3478 19.4435 27.384C19.4474 27.4202 19.4424 27.4569 19.4288 27.4907C19.4153 27.5246 19.3937 27.5546 19.3659 27.5781C19.338 27.6016 19.3048 27.618 19.2692 27.6257Z"
                  fill="url(#paint8_linear_3431_10433)"
                />
                <path
                  d="M18.1548 27.4029H9.97766C9.9421 27.3982 9.90826 27.3848 9.87926 27.3637C9.85025 27.3426 9.82699 27.3145 9.81164 27.2821C9.79628 27.2497 9.78932 27.214 9.79137 27.1781C9.79341 27.1423 9.80443 27.1076 9.82338 27.0772L10.2005 26.3829C10.7215 25.4033 11.5625 24.6318 12.5834 24.1972C13.5314 23.8117 14.5925 23.8117 15.5405 24.1972C16.5641 24.6362 17.4055 25.414 17.9234 26.4L18.3005 27.0943C18.4119 27.2143 18.292 27.4029 18.1548 27.4029Z"
                  fill="url(#paint9_linear_3431_10433)"
                />
                <path
                  d="M14.1261 23.9143C13.3702 24.1683 12.678 24.5824 12.0967 25.1282C11.5154 25.674 11.0586 26.3388 10.7575 27.0771C10.689 27.2143 10.7575 27.4029 10.8861 27.4029H12.4118C12.309 27.4029 12.0004 25.6629 14.1261 23.9143Z"
                  fill="url(#paint10_linear_3431_10433)"
                />
                <path
                  d="M13.9887 19.9715C13.9346 19.1387 13.969 18.3026 14.0915 17.4772H13.543C13.6801 19.1372 13.6801 20.8057 13.543 22.4658V24.18L14.0915 23.9057V22.4829C13.9678 21.6518 13.9333 20.8099 13.9887 19.9715Z"
                  fill="url(#paint11_linear_3431_10433)"
                />
                <path
                  d="M12.4121 17.4772C12.8332 17.8798 13.1305 18.3942 13.2693 18.96C13.467 19.0107 13.6673 19.0508 13.8693 19.08H14.6064C14.8084 19.0508 15.0087 19.0107 15.2064 18.96C15.3451 18.3942 15.6425 17.8798 16.0635 17.4772H12.4121Z"
                  fill="url(#paint12_linear_3431_10433)"
                />
                <path
                  d="M23.1254 1.76576C23.0915 1.43915 22.9379 1.13668 22.6941 0.91667C22.4504 0.696658 22.1338 0.574705 21.8054 0.574341H6.54828C6.22047 0.576592 5.90489 0.699188 5.66153 0.918841C5.41817 1.13849 5.26399 1.43988 5.22828 1.76576C3.96828 12.96 10.3711 17.4429 13.7997 17.9401H14.5369C17.9654 17.4429 24.4111 12.96 23.1254 1.76576Z"
                  fill="url(#paint13_linear_3431_10433)"
                />
                <path
                  d="M20.7771 1.76576C20.7744 1.46793 20.6617 1.18162 20.4608 0.96174C20.2599 0.741858 19.9849 0.603901 19.6885 0.574341H7.14854C6.85144 0.602047 6.57535 0.73944 6.37408 0.959726C6.17282 1.18001 6.06082 1.46737 6.05998 1.76576C5.03141 12.96 10.3457 17.4429 13.1143 17.9401H13.7228C16.5343 17.4429 21.84 12.96 20.7771 1.76576Z"
                  fill="url(#paint14_linear_3431_10433)"
                />
                <path
                  d="M10.6981 1.63719C10.7409 1.03719 11.0924 0.574341 11.5038 0.574341H8.17807C7.76664 0.574341 7.42378 1.03719 7.37235 1.63719C6.74664 9.85719 9.27521 14.0143 11.3409 15.4715C11.4335 15.5425 11.5462 15.5821 11.6629 15.5847C11.7795 15.5872 11.8939 15.5525 11.9894 15.4856C12.085 15.4187 12.1567 15.3231 12.1942 15.2127C12.2318 15.1023 12.2331 14.9827 12.1981 14.8715C10.8193 10.6045 10.3093 6.10452 10.6981 1.63719Z"
                  fill="url(#paint15_linear_3431_10433)"
                />
                <path
                  d="M22.2097 0.574341H6.14681C5.79994 0.576476 5.46595 0.706014 5.20835 0.938328C4.95075 1.17064 4.7875 1.48953 4.74967 1.83434C4.74967 2.04006 4.7068 2.24577 4.68966 2.45149C4.68356 2.51199 4.69041 2.57309 4.70975 2.63075C4.72909 2.6884 4.76048 2.7413 4.80183 2.78589C4.84317 2.83048 4.89353 2.86574 4.94957 2.88937C5.0056 2.913 5.06602 2.92443 5.12681 2.9229H23.2297C23.2904 2.92301 23.3506 2.91069 23.4064 2.8867C23.4622 2.86271 23.5125 2.82756 23.5543 2.7834C23.596 2.73925 23.6283 2.68704 23.6491 2.62996C23.67 2.57288 23.6789 2.51214 23.6754 2.45149C23.6754 2.24577 23.6325 2.04006 23.6068 1.83434C23.569 1.48953 23.4057 1.17064 23.1481 0.938328C22.8905 0.706014 22.5565 0.576476 22.2097 0.574341Z"
                  fill="url(#paint16_linear_3431_10433)"
                />
                <path
                  d="M19.1915 0.574341H6.41151C6.10399 0.610365 5.82072 0.759122 5.61649 0.991847C5.41227 1.22457 5.30157 1.52474 5.30579 1.83434L5.25436 2.45149C5.24464 2.50574 5.24616 2.56141 5.25881 2.61505C5.27146 2.6687 5.29498 2.71918 5.32792 2.76337C5.36086 2.80756 5.40252 2.8445 5.45031 2.87195C5.49811 2.89939 5.55103 2.91673 5.6058 2.9229H20.0315C20.0861 2.91688 20.1387 2.89955 20.1862 2.872C20.2336 2.84445 20.2748 2.80729 20.3071 2.76292C20.3393 2.71855 20.362 2.66792 20.3736 2.61429C20.3852 2.56067 20.3854 2.50522 20.3744 2.45149C20.3744 2.24577 20.3744 2.04006 20.3315 1.83434C20.3329 1.51996 20.2168 1.2164 20.0059 0.983268C19.7949 0.750138 19.5045 0.604279 19.1915 0.574341Z"
                  fill="url(#paint17_linear_3431_10433)"
                />
                <path
                  d="M6.87401 1.63719C6.83973 2.0829 6.81402 2.51148 6.79688 2.9229H10.1311C10.1311 2.51148 10.1312 2.06576 10.1997 1.63719C10.1997 1.03719 10.5854 0.574341 10.9969 0.574341H7.67115C7.26829 0.574341 6.91687 1.05433 6.87401 1.63719Z"
                  fill="url(#paint18_linear_3431_10433)"
                />
                <path d="M5.0918 3.51435H23.2632C23.2632 3.30007 23.2632 3.08577 23.2632 2.86292H5.12609C5.10895 3.08577 5.10037 3.30007 5.0918 3.51435Z" fill="url(#paint19_linear_3431_10433)" />
                <path
                  d="M14.1773 13.1572C16.3313 13.1572 18.0773 11.4111 18.0773 9.25718C18.0773 7.10327 16.3313 5.35718 14.1773 5.35718C12.0234 5.35718 10.2773 7.10327 10.2773 9.25718C10.2773 11.4111 12.0234 13.1572 14.1773 13.1572Z"
                  fill="url(#paint20_linear_3431_10433)"
                />
                <path
                  d="M14.3915 7.05428L14.8887 8.05715C14.9057 8.09262 14.9308 8.12352 14.9621 8.14736C14.9934 8.17121 15.0299 8.18728 15.0686 8.19427L16.1744 8.34857C16.2187 8.3554 16.2603 8.37454 16.2944 8.40382C16.3284 8.43309 16.3535 8.47132 16.3669 8.51418C16.3803 8.55703 16.3814 8.6028 16.3701 8.64625C16.3588 8.6897 16.3355 8.7291 16.3029 8.75998L15.5058 9.53999C15.4763 9.56449 15.4543 9.59674 15.4422 9.63308C15.4301 9.66943 15.4284 9.70844 15.4372 9.74572L15.6258 10.8514C15.6352 10.8953 15.6317 10.941 15.6156 10.9829C15.5996 11.0248 15.5717 11.0611 15.5354 11.0875C15.4991 11.1138 15.4558 11.129 15.411 11.1312C15.3662 11.1335 15.3217 11.1226 15.2829 11.1L14.2886 10.5857C14.2554 10.5643 14.2167 10.5529 14.1772 10.5529C14.1377 10.5529 14.099 10.5643 14.0658 10.5857L13.0715 11.1C13.0327 11.1226 12.9882 11.1335 12.9434 11.1312C12.8986 11.129 12.8554 11.1138 12.8191 11.0875C12.7827 11.0611 12.7548 11.0248 12.7388 10.9829C12.7227 10.941 12.7192 10.8953 12.7286 10.8514L12.9172 9.74572C12.9261 9.70844 12.9244 9.66943 12.9122 9.63308C12.9001 9.59674 12.8781 9.56449 12.8487 9.53999L12.0515 8.75998C12.0189 8.7291 11.9956 8.6897 11.9843 8.64625C11.973 8.6028 11.9741 8.55703 11.9875 8.51418C12.0009 8.47132 12.026 8.43309 12.0601 8.40382C12.0941 8.37454 12.1357 8.3554 12.1801 8.34857L13.2944 8.19427C13.332 8.18738 13.3674 8.17128 13.3974 8.14734C13.4273 8.1234 13.4508 8.09241 13.4658 8.05715L13.9629 7.05428C13.9829 7.01461 14.0136 6.98128 14.0514 6.95799C14.0892 6.9347 14.1328 6.92236 14.1772 6.92236C14.2216 6.92236 14.2652 6.9347 14.303 6.95799C14.3409 6.98128 14.3715 7.01461 14.3915 7.05428Z"
                  fill="white"
                />
                <defs>
                  <linearGradient id="paint0_linear_3431_10433" x1="16.0535" y1="9.25736" x2="29.8818" y2="9.25733" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_3431_10433" x1="-1.31755" y1="9.25751" x2="12.5107" y2="9.25748" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_3431_10433" x1="12.061" y1="18.7244" x2="14.5364" y2="18.7244" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint3_linear_3431_10433" x1="13.852" y1="18.7244" x2="16.3274" y2="18.7244" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint4_linear_3431_10433" x1="12.061" y1="21.2187" x2="14.5364" y2="21.2187" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint5_linear_3431_10433" x1="13.852" y1="21.2187" x2="16.3274" y2="21.2187" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint6_linear_3431_10433" x1="11.7353" y1="23.7258" x2="16.6861" y2="23.7258" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint7_linear_3431_10433" x1="12.3879" y1="21.236" x2="15.7635" y2="21.236" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint8_linear_3431_10433" x1="6.99939" y1="25.8119" x2="21.5509" y2="25.8118" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint9_linear_3431_10433" x1="8.23586" y1="25.6556" x2="20.0551" y2="25.6555" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint10_linear_3431_10433" x1="10.1131" y1="25.6587" x2="14.8052" y2="25.6587" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint11_linear_3431_10433" x1="13.4432" y1="20.8288" x2="14.2012" y2="20.8288" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint12_linear_3431_10433" x1="11.7482" y1="18.2787" x2="16.7938" y2="18.2786" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint13_linear_3431_10433" x1="1.75256" y1="9.25782" x2="26.9378" y2="9.25777" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint14_linear_3431_10433" x1="3.20494" y1="9.25782" x2="23.9131" y2="9.25779" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint15_linear_3431_10433" x1="6.37575" y1="8.08011" x2="13.213" y2="8.0801" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint16_linear_3431_10433" x1="1.23502" y1="1.74877" x2="27.4739" y2="1.74837" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint17_linear_3431_10433" x1="2.49633" y1="1.74871" x2="23.4094" y2="1.74845" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint18_linear_3431_10433" x1="6.03324" y1="1.74871" x2="11.8369" y2="1.74869" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint19_linear_3431_10433" x1="1.7879" y1="3.18866" x2="26.8975" y2="3.18733" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                  <linearGradient id="paint20_linear_3431_10433" x1="8.85916" y1="9.25746" x2="19.6373" y2="9.25744" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D8D6EF" />
                    <stop offset="0.487457" stop-color="#9F9CBF" />
                    <stop offset="1" stop-color="#D8D6EF" />
                  </linearGradient>
                </defs>
              </svg>
              <svg v-else-if="idx + 1 === 3" width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0273 4.8H26.6759C26.8586 4.79667 27.0396 4.83572 27.2047 4.91408C27.3698 4.99243 27.5145 5.10798 27.6274 5.25164C27.7403 5.39531 27.8184 5.56316 27.8556 5.74208C27.8928 5.921 27.888 6.10611 27.8416 6.28287L26.7702 10.4314C26.5303 11.3702 25.9849 12.2024 25.2197 12.7968C24.4546 13.3912 23.5134 13.714 22.5445 13.7143H17.873"
                  stroke="url(#paint0_linear_3431_10526)"
                  stroke-width="1.17"
                  stroke-miterlimit="10"
                />
                <path
                  d="M7.32922 4.80005H1.68065C1.5001 4.80069 1.32209 4.84264 1.16026 4.9227C0.99844 5.00277 0.857088 5.11884 0.747046 5.26197C0.637005 5.40511 0.56119 5.57152 0.52541 5.74848C0.489631 5.92545 0.494842 6.10827 0.540634 6.28292L1.61206 10.4315C1.85191 11.3702 2.39742 12.2024 3.16255 12.7968C3.92768 13.3912 4.86888 13.714 5.83777 13.7143H10.5092"
                  stroke="url(#paint1_linear_3431_10526)"
                  stroke-width="1.17"
                  stroke-miterlimit="10"
                />
                <path d="M14.1781 19.9715H13.0639C13.4924 19.3115 13.1153 18.2143 12.3867 17.4772H14.1781V19.9715Z" fill="url(#paint2_linear_3431_10526)" />
                <path d="M14.1777 19.9715H15.292C14.8634 19.3115 15.2406 18.2143 15.9692 17.4772H14.1777V19.9715Z" fill="url(#paint3_linear_3431_10526)" />
                <path d="M14.1781 19.9714H13.0639C13.4924 20.6314 13.1153 21.7286 12.3867 22.4657H14.1781V19.9714Z" fill="url(#paint4_linear_3431_10526)" />
                <path d="M14.1777 19.9714H15.292C14.8634 20.6314 15.2406 21.7286 15.9692 22.4657H14.1777V19.9714Z" fill="url(#paint5_linear_3431_10526)" />
                <path d="M15.9696 22.4657H12.3867V24.9857H15.9696V22.4657Z" fill="url(#paint6_linear_3431_10526)" />
                <path
                  d="M14.8206 19.9715C14.7054 19.5482 14.6865 19.1044 14.7651 18.6728C14.8437 18.2412 15.0179 17.8327 15.2749 17.4772H12.832C13.4492 19.0828 13.4492 20.8602 12.832 22.4657V24.9943H15.2749V22.4829C15.0153 22.1253 14.8397 21.7138 14.761 21.279C14.6824 20.8442 14.7027 20.3973 14.8206 19.9715Z"
                  fill="url(#paint7_linear_3431_10526)"
                />
                <path
                  d="M19.2692 27.6257H9.12923C9.08993 27.6244 9.05168 27.6126 9.01838 27.5917C8.98507 27.5708 8.9579 27.5414 8.93961 27.5066C8.92133 27.4718 8.91258 27.4327 8.91427 27.3934C8.91596 27.3542 8.92801 27.316 8.94921 27.2829L9.41208 26.5714C10.1136 25.5061 11.1516 24.7065 12.3607 24.3C13.5415 23.8971 14.8227 23.8971 16.0035 24.3C17.21 24.707 18.2452 25.5067 18.9435 26.5714L19.4064 27.2829C19.4268 27.313 19.4396 27.3478 19.4435 27.384C19.4474 27.4202 19.4424 27.4569 19.4288 27.4907C19.4153 27.5246 19.3937 27.5546 19.3659 27.5781C19.338 27.6016 19.3048 27.618 19.2692 27.6257Z"
                  fill="url(#paint8_linear_3431_10526)"
                />
                <path
                  d="M18.1548 27.4029H9.97766C9.9421 27.3982 9.90826 27.3848 9.87926 27.3637C9.85025 27.3426 9.82699 27.3145 9.81164 27.2821C9.79628 27.2497 9.78932 27.214 9.79137 27.1781C9.79341 27.1423 9.80443 27.1076 9.82338 27.0772L10.2005 26.3829C10.7215 25.4033 11.5625 24.6318 12.5834 24.1972C13.5314 23.8117 14.5925 23.8117 15.5405 24.1972C16.5641 24.6362 17.4055 25.414 17.9234 26.4L18.3005 27.0943C18.4119 27.2143 18.292 27.4029 18.1548 27.4029Z"
                  fill="url(#paint9_linear_3431_10526)"
                />
                <path
                  d="M14.1261 23.9143C13.3702 24.1683 12.678 24.5824 12.0967 25.1282C11.5154 25.674 11.0586 26.3388 10.7575 27.0771C10.689 27.2143 10.7575 27.4029 10.8861 27.4029H12.4118C12.309 27.4029 12.0004 25.6629 14.1261 23.9143Z"
                  fill="url(#paint10_linear_3431_10526)"
                />
                <path
                  d="M13.9887 19.9715C13.9346 19.1387 13.969 18.3026 14.0915 17.4772H13.543C13.6801 19.1372 13.6801 20.8057 13.543 22.4658V24.18L14.0915 23.9057V22.4829C13.9678 21.6518 13.9333 20.8099 13.9887 19.9715Z"
                  fill="url(#paint11_linear_3431_10526)"
                />
                <path
                  d="M12.4121 17.4772C12.8332 17.8798 13.1305 18.3942 13.2693 18.96C13.467 19.0107 13.6673 19.0508 13.8693 19.08H14.6064C14.8084 19.0508 15.0087 19.0107 15.2064 18.96C15.3451 18.3942 15.6425 17.8798 16.0635 17.4772H12.4121Z"
                  fill="url(#paint12_linear_3431_10526)"
                />
                <path
                  d="M23.1254 1.76576C23.0915 1.43915 22.9379 1.13668 22.6941 0.91667C22.4504 0.696658 22.1338 0.574705 21.8054 0.574341H6.54828C6.22047 0.576592 5.90489 0.699188 5.66153 0.918841C5.41817 1.13849 5.26399 1.43988 5.22828 1.76576C3.96828 12.96 10.3711 17.4429 13.7997 17.9401H14.5369C17.9654 17.4429 24.4111 12.96 23.1254 1.76576Z"
                  fill="url(#paint13_linear_3431_10526)"
                />
                <path
                  d="M20.7771 1.76576C20.7744 1.46793 20.6617 1.18162 20.4608 0.96174C20.2599 0.741858 19.9849 0.603901 19.6885 0.574341H7.14854C6.85144 0.602047 6.57535 0.73944 6.37408 0.959726C6.17282 1.18001 6.06082 1.46737 6.05998 1.76576C5.03141 12.96 10.3457 17.4429 13.1143 17.9401H13.7228C16.5343 17.4429 21.84 12.96 20.7771 1.76576Z"
                  fill="url(#paint14_linear_3431_10526)"
                />
                <path
                  d="M10.6981 1.63719C10.7409 1.03719 11.0924 0.574341 11.5038 0.574341H8.17807C7.76664 0.574341 7.42378 1.03719 7.37235 1.63719C6.74664 9.85719 9.27521 14.0143 11.3409 15.4715C11.4335 15.5425 11.5462 15.5821 11.6629 15.5847C11.7795 15.5872 11.8939 15.5525 11.9894 15.4856C12.085 15.4187 12.1567 15.3231 12.1942 15.2127C12.2318 15.1023 12.2331 14.9827 12.1981 14.8715C10.8193 10.6045 10.3093 6.10452 10.6981 1.63719Z"
                  fill="url(#paint15_linear_3431_10526)"
                />
                <path
                  d="M22.2097 0.574341H6.14681C5.79994 0.576476 5.46595 0.706014 5.20835 0.938328C4.95075 1.17064 4.7875 1.48953 4.74967 1.83434C4.74967 2.04006 4.7068 2.24577 4.68966 2.45149C4.68356 2.51199 4.69041 2.57309 4.70975 2.63075C4.72909 2.6884 4.76048 2.7413 4.80183 2.78589C4.84317 2.83048 4.89353 2.86574 4.94957 2.88937C5.0056 2.913 5.06602 2.92443 5.12681 2.9229H23.2297C23.2904 2.92301 23.3506 2.91069 23.4064 2.8867C23.4622 2.86271 23.5125 2.82756 23.5543 2.7834C23.596 2.73925 23.6283 2.68704 23.6491 2.62996C23.67 2.57288 23.6789 2.51214 23.6754 2.45149C23.6754 2.24577 23.6325 2.04006 23.6068 1.83434C23.569 1.48953 23.4057 1.17064 23.1481 0.938328C22.8905 0.706014 22.5565 0.576476 22.2097 0.574341Z"
                  fill="url(#paint16_linear_3431_10526)"
                />
                <path
                  d="M19.1915 0.574341H6.41151C6.10399 0.610365 5.82072 0.759122 5.61649 0.991847C5.41227 1.22457 5.30157 1.52474 5.30579 1.83434L5.25436 2.45149C5.24464 2.50574 5.24616 2.56141 5.25881 2.61505C5.27146 2.6687 5.29498 2.71918 5.32792 2.76337C5.36086 2.80756 5.40252 2.8445 5.45031 2.87195C5.49811 2.89939 5.55103 2.91673 5.6058 2.9229H20.0315C20.0861 2.91688 20.1387 2.89955 20.1862 2.872C20.2336 2.84445 20.2748 2.80729 20.3071 2.76292C20.3393 2.71855 20.362 2.66792 20.3736 2.61429C20.3852 2.56067 20.3854 2.50522 20.3744 2.45149C20.3744 2.24577 20.3744 2.04006 20.3315 1.83434C20.3329 1.51996 20.2168 1.2164 20.0059 0.983268C19.7949 0.750138 19.5045 0.604279 19.1915 0.574341Z"
                  fill="url(#paint17_linear_3431_10526)"
                />
                <path
                  d="M6.87401 1.63719C6.83973 2.0829 6.81402 2.51148 6.79688 2.9229H10.1311C10.1311 2.51148 10.1312 2.06576 10.1997 1.63719C10.1997 1.03719 10.5854 0.574341 10.9969 0.574341H7.67115C7.26829 0.574341 6.91687 1.05433 6.87401 1.63719Z"
                  fill="url(#paint18_linear_3431_10526)"
                />
                <path d="M5.0918 3.51435H23.2632C23.2632 3.30007 23.2632 3.08577 23.2632 2.86292H5.12609C5.10895 3.08577 5.10037 3.30007 5.0918 3.51435Z" fill="url(#paint19_linear_3431_10526)" />
                <path
                  d="M14.1773 13.1572C16.3313 13.1572 18.0773 11.4111 18.0773 9.25718C18.0773 7.10327 16.3313 5.35718 14.1773 5.35718C12.0234 5.35718 10.2773 7.10327 10.2773 9.25718C10.2773 11.4111 12.0234 13.1572 14.1773 13.1572Z"
                  fill="url(#paint20_linear_3431_10526)"
                />
                <path
                  d="M14.3915 7.05428L14.8887 8.05715C14.9057 8.09262 14.9308 8.12352 14.9621 8.14736C14.9934 8.17121 15.0299 8.18728 15.0686 8.19427L16.1744 8.34857C16.2187 8.3554 16.2603 8.37454 16.2944 8.40382C16.3284 8.43309 16.3535 8.47132 16.3669 8.51418C16.3803 8.55703 16.3814 8.6028 16.3701 8.64625C16.3588 8.6897 16.3355 8.7291 16.3029 8.75998L15.5058 9.53999C15.4763 9.56449 15.4543 9.59674 15.4422 9.63308C15.4301 9.66943 15.4284 9.70844 15.4372 9.74572L15.6258 10.8514C15.6352 10.8953 15.6317 10.941 15.6156 10.9829C15.5996 11.0248 15.5717 11.0611 15.5354 11.0875C15.4991 11.1138 15.4558 11.129 15.411 11.1312C15.3662 11.1335 15.3217 11.1226 15.2829 11.1L14.2886 10.5857C14.2554 10.5643 14.2167 10.5529 14.1772 10.5529C14.1377 10.5529 14.099 10.5643 14.0658 10.5857L13.0715 11.1C13.0327 11.1226 12.9882 11.1335 12.9434 11.1312C12.8986 11.129 12.8554 11.1138 12.8191 11.0875C12.7827 11.0611 12.7548 11.0248 12.7388 10.9829C12.7227 10.941 12.7192 10.8953 12.7286 10.8514L12.9172 9.74572C12.9261 9.70844 12.9244 9.66943 12.9122 9.63308C12.9001 9.59674 12.8781 9.56449 12.8487 9.53999L12.0515 8.75998C12.0189 8.7291 11.9956 8.6897 11.9843 8.64625C11.973 8.6028 11.9741 8.55703 11.9875 8.51418C12.0009 8.47132 12.026 8.43309 12.0601 8.40382C12.0941 8.37454 12.1357 8.3554 12.1801 8.34857L13.2944 8.19427C13.332 8.18738 13.3674 8.17128 13.3974 8.14734C13.4273 8.1234 13.4508 8.09241 13.4658 8.05715L13.9629 7.05428C13.9829 7.01461 14.0136 6.98128 14.0514 6.95799C14.0892 6.9347 14.1328 6.92236 14.1772 6.92236C14.2216 6.92236 14.2652 6.9347 14.303 6.95799C14.3409 6.98128 14.3715 7.01461 14.3915 7.05428Z"
                  fill="white"
                />
                <defs>
                  <linearGradient id="paint0_linear_3431_10526" x1="16.0535" y1="9.25736" x2="29.8818" y2="9.25733" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_3431_10526" x1="-1.31755" y1="9.25751" x2="12.5107" y2="9.25748" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_3431_10526" x1="12.061" y1="18.7244" x2="14.5364" y2="18.7244" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint3_linear_3431_10526" x1="13.852" y1="18.7244" x2="16.3274" y2="18.7244" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint4_linear_3431_10526" x1="12.061" y1="21.2187" x2="14.5364" y2="21.2187" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint5_linear_3431_10526" x1="13.852" y1="21.2187" x2="16.3274" y2="21.2187" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint6_linear_3431_10526" x1="11.7353" y1="23.7258" x2="16.6861" y2="23.7258" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint7_linear_3431_10526" x1="12.3879" y1="21.236" x2="15.7635" y2="21.236" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint8_linear_3431_10526" x1="6.99939" y1="25.8119" x2="21.5509" y2="25.8118" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint9_linear_3431_10526" x1="8.23586" y1="25.6556" x2="20.0551" y2="25.6555" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint10_linear_3431_10526" x1="10.1131" y1="25.6587" x2="14.8052" y2="25.6587" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint11_linear_3431_10526" x1="13.4432" y1="20.8288" x2="14.2012" y2="20.8288" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint12_linear_3431_10526" x1="11.7482" y1="18.2787" x2="16.7938" y2="18.2786" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint13_linear_3431_10526" x1="1.75256" y1="9.25782" x2="26.9378" y2="9.25777" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint14_linear_3431_10526" x1="3.20494" y1="9.25782" x2="23.9131" y2="9.25779" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint15_linear_3431_10526" x1="6.37575" y1="8.08011" x2="13.213" y2="8.0801" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint16_linear_3431_10526" x1="1.23502" y1="1.74877" x2="27.4739" y2="1.74837" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint17_linear_3431_10526" x1="2.49633" y1="1.74871" x2="23.4094" y2="1.74845" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint18_linear_3431_10526" x1="6.03324" y1="1.74871" x2="11.8369" y2="1.74869" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint19_linear_3431_10526" x1="1.7879" y1="3.18866" x2="26.8975" y2="3.18733" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                  <linearGradient id="paint20_linear_3431_10526" x1="8.85916" y1="9.25746" x2="19.6373" y2="9.25744" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D1A694" />
                    <stop offset="0.487457" stop-color="#7E443C" />
                    <stop offset="1" stop-color="#D1A693" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div>
              <div class="tours2__favorite-item-block-label">{{ $t("page.tournaments.t_gain") }}</div>
              <div class="tours2__favorite-item-block-text tours2__favorite-item-block-text--big">{{ award[idx + 1].toLocaleString() }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tours2__bottom">
      <div class="tours2__table">
        <TourTable :top="top" :award="award" />
        <table v-if="false">
          <thead>
            <tr>
              <th>
                <div>{{ $t("page.tournaments.t_place") }}</div>
              </th>
              <th>
                <div>{{ $t("page.tournaments.t_user") }}</div>
              </th>
              <th>
                <div>{{ $t("page.tournaments.t_points") }}</div>
              </th>
              <th>
                <div>{{ $t("page.tournaments.t_gain") }}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :class="{ active: $auth.loggedIn && row.user.id === $auth.user.id }" v-for="(row, idx) in top" :key="`t-r-${idx}`">
              <td>
                <div>{{ row.place }}</div>
              </td>
              <td>
                <div>{{ row.user && row.user.name }}</div>
              </td>
              <td>
                <div>{{ parseFloat(parseFloat(row.total).toFixed(2)).toLocaleString() }}</div>
              </td>
              <td>
                <div>{{ (award[row.place] || 0).toLocaleString() }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="tours2__btn-history" v-if="history.length" type="button" @click="$vfm.show('tours_list')">{{ $t("page.tournaments.history") }}</button>
      </div>
      <div>
        <div class="tours2__spoilers">
          <VSpoiler static :gray="false">
            <template #header>{{ $t("page.tournaments.q1") }}</template>
            <ul class="tours2__spoilers-list">
              <li>{{ $t("page.tournaments.a1_1") }}</li>
              <li>{{ $t("page.tournaments.a1_2") }}</li>
              <li>{{ $t("page.tournaments.a1_3") }}</li>
              <li>{{ $t("page.tournaments.a1_4") }}</li>
            </ul>
            <div v-for="(item, i) in paytables" :key="i" class="v-spoiler alt">
              <div class="v-spoiler__header alt">
                <div class="v-spoiler__icon">
                  <FontIcon :icon="item.icon" size="24" />
                </div>
                <span class="v-spoiler__title alt">{{ item.name }}</span>
                <span class="paytable-value">{{ item.value }}</span>
              </div>
            </div>
          </VSpoiler>
          <VSpoiler :gray="false">
            <template #header>{{ $t("page.tournaments.q2") }}</template>
            {{ $t("page.tournaments.q2_a") }}
          </VSpoiler>
          <VSpoiler :gray="false">
            <template #header>{{ $t("page.tournaments.q3") }}</template>
            {{ $t("page.tournaments.q3_a") }}
          </VSpoiler>
          <VSpoiler :gray="false">
            <template #header>{{ $t("page.tournaments.q4") }}</template>
            {{ $t("page.tournaments.q4_a") }}
          </VSpoiler>
        </div>
      </div>
    </div>
    <ModalToursList v-model="modal" />
  </div>
</template>

<script>
import VSpoiler from "~~/components/VSpoiler.vue";
import TourPlace from "~~/components/Page/Tournaments/TourPlace.vue";
import utils from "@/effects/useEffects";
import ModalToursList from "@/components/Modal/ModalToursList.vue";
import TourTable from "~~/components/Page/Tournaments/TourTable.vue";

export default {
  components: { VSpoiler, TourPlace, ModalToursList, TourTable },
  setup() {
    const { $axios } = useNuxtApp();

    onMounted(() => {
      const el = document.querySelector("video");
      if (el) el.play();
    });

    const top = ref([]);
    const history = ref([]);
    const timer = ref(0);
    const tasks = ref({});
    const modal = ref(false);
    const award = ref({
      1: 1000,
      2: 1000,
      3: 1000,
      4: 1000,
      5: 1000,
    });
    let interval = null;

    const icons = {
      "dice:classic": "dice",
      "dice:bonus": "dice",
      bet: "casino",
      trainx: "bonusBuy",
    };

    const title = {
      trainx: "Money Train",
    };

    const paytables = computed(() =>
      Object.entries(tasks.value).map(([key, value]) => ({
        name: title[key] || (key === "bet" ? "Slots" : key.replace(":", " ")),
        value: value,
        icon: icons[key] || key,
      }))
    );

    const onInit = async () => {
      await $axios.$get("bonus/tournaments/init").then(({ response }) => {
        if (response?.top) {
          timer.value = response.timer;
          top.value = response.top;
          award.value = response.award.place;
          tasks.value = response.award.tasks;

          interval = setInterval(() => {
            timer.value--;
            if (timer.value <= 0) clearInterval(interval);
          }, 1000);
        }
      });
    };
    const onGetHistory = async () => {
      await $axios.$get("bonus/tournaments/history").then(({ response }) => {
        if (response.data) {
          history.value = response.data;
        }
      });
    };
    onInit();
    onGetHistory();

    const getHistoryBank = (item) => {
      return Object.values(item.award.place).reduce((a, b) => (a += b), 0);
    };
    const getHistoryDate = (item) => {
      return utils.getDate(item.finished_at).split(" ")[0];
    };

    const bank = computed(() => Object.values(award.value).reduce((a, b) => (a += b), 0));

    const sformat = (s) => {
      const fm = [
        Math.floor(s / 60 / 60 / 24), // DAYS
        Math.floor(s / 60 / 60) % 24, // HOURS
        Math.floor(s / 60) % 60, // MINUTES
        s % 60, // SECONDS
      ];
      return fm
        .reduce((a, v) => {
          const val = (v < 10 ? "0" : "") + v;
          a.push(val);
          return a;
        }, [])
        .join(":");
    };

    const timeout = computed(() => {
      return sformat(timer.value);
    });

    return {
      top,
      award,
      history,
      modal,
      bank,
      getHistoryBank,
      getHistoryDate,
      timeout,
      paytables,
      tasks,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/modals/ref.scss";

.tour-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 6px;
  video {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%);
  }
}

@keyframes animateAmount {
  0% {
    transform: scale(1);
  }
  4% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.08);
  }
  37% {
    transform: scale(1);
  }
  56% {
    transform: scale(1.02);
  }
  74% {
    transform: scale(1);
  }
  85% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

.animate_amount {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: animateAmount 5s linear forwards infinite;
}

.v-spoiler__icon {
  svg {
    color: #ffffff;
  }
}
.v-spoiler.alt {
  margin-top: 12px;
  box-shadow: none !important;
}
.v-spoiler__title.alt {
  text-transform: capitalize;
}
.v-spoiler__header.alt {
  background: var(--bg_secondary__100);
  border-radius: 8px;
  padding-right: 20px;
}
.paytable-value {
  margin-left: auto;
  color: var(--color_main__100);
  font-weight: 700;
  font-size: 16px;
}
.tours2 {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;

  @media screen and (max-width: 760px) {
    padding: 12px;
  }

  &__btn-history {
    background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%);
    border-radius: 8px;
    height: 55px;
    width: 200px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  &__header {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &-item {
      background-image: linear-gradient(
        45deg,
        rgba(128, 119, 183, 0.05) 25%,
        rgba(230, 224, 254, 0.05) 25%,
        rgba(230, 224, 254, 0.05) 50%,
        rgba(128, 119, 183, 0.05) 50%,
        rgba(128, 119, 183, 0.05) 75%,
        rgba(230, 224, 254, 0.05) 75%,
        rgba(230, 224, 254, 0.05) 100%
      );
      background-size: 42.43px 42.43px;
      border: 1px solid #e4e6f7;
      border-radius: 12px;
      padding: 16px;
      display: flex;
      align-items: center;

      &-icon {
        width: 55px;
        height: 55px;
        margin-right: 11px;
      }
      &-label {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;

        color: #c2bdd6;
      }
      &-text {
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        /* identical to box height */

        letter-spacing: 0.05em;

        color: #262a31;
      }
      &-date {
        background: linear-gradient(90deg, #ffc658 -18.18%, #f4a100 49.18%, #ffc658 120%);
        border-radius: 8px;
        width: 128px;
        height: 48px;
        margin-left: auto;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.03em;

        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__favorite {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    margin-top: 20px;
    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    &-item {
      padding: 24px;
      border: 1px solid rgba($color: #cdccd6, $alpha: 0.25);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;

      &-cup {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 90%;
        height: auto;
        transform: translate(-50%, -50%);
      }

      &-bg {
        opacity: 0.25;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        &-1 {
          background: radial-gradient(100% 100% at 50.12% 0%, #795bff 0%, rgba(152, 166, 255, 0.09) 55.6%, rgba(208, 211, 231, 0.08) 100%);
        }
        &-2 {
          background: radial-gradient(100% 100% at 50.12% 0%, #77ad41 0%, #dbffb8 55.6%, rgba(208, 211, 231, 0.08) 100%);
        }
        &-3 {
          background: radial-gradient(100% 100% at 50.12% 0%, #795bff 0%, rgba(152, 166, 255, 0.29) 55.6%, rgba(208, 211, 231, 0.08) 100%);
        }
      }
      &-image {
        width: 178px;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        margin-bottom: 26px;
      }

      &-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
        z-index: 1;
      }

      &-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid #e4e6f7;
        border-radius: 8px;
        &:last-child {
          flex-direction: row;
          justify-content: flex-start;
          grid-column: 1 / -1;

          & > div:last-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            & > div:last-child {
              margin-top: 4px;
            }
          }
        }
        &-place {
          background: linear-gradient(180deg, #f1f1ff 0%, #ffffff 100%);
          border: 1px solid #e4e6f6;
          border-radius: 8px;
          width: 50px;
          height: 50px;
          margin-right: 15px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-label {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          letter-spacing: 0.15em;

          color: #c2bdd6;
        }
        &-text {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          color: #262a31;
          text-align: center;

          &--big {
            font-size: 25px;
          }
        }
      }
    }
  }
  &__bottom {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    gap: 20px;

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  &__table {
    grid-column: span 2 / span 2;

    @media screen and (max-width: 1280px) {
      grid-column: span 1 / span 1;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      max-width: 100%;

      thead,
      tbody {
        tr {
          th,
          td {
            div {
              height: 60px;
              font-weight: 700;
              font-size: 14px;
              line-height: 60px;
              /* identical to box height, or 429% */

              letter-spacing: 0.15em;

              color: #c2bdd6;
              text-align: center;

              border-top: 1px solid #e3e9f2;
              border-bottom: 1px solid #e3e9f2;
              margin-bottom: 14px;
            }
            &:first-child div {
              border-left: 1px solid #e3e9f2;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            &:last-child div {
              border-right: 1px solid #e3e9f2;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 0;
            div {
              height: 60px;
              font-weight: 700;
              font-size: 16px;
              line-height: 60px;
              /* identical to box height, or 375% */

              color: #000000;
              letter-spacing: normal;

              white-space: nowrap;

              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              border-top: 1px solid #e3e9f2;
              border-bottom: 1px solid #e3e9f2;
              margin-bottom: 10px;
            }
            &:first-child div {
              border-left: 1px solid #e3e9f2;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            &:last-child div {
              border-right: 1px solid #e3e9f2;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }

          &:not(.active) td:last-child div {
            @supports (background-clip: text) {
              background: linear-gradient(90deg, #76ac41 3.33%, #97d25b 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }

          &.active {
            position: relative;
            z-index: 1;
            &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: calc(100% - 10px);
              width: 100%;
              background: url(@/assets/img/page/tournaments/row.svg), radial-gradient(48.33% 193.33% at 51.67% 50%, #97d25b 0%, #76ac41 34.9%);

              border-radius: 8px;
              z-index: -1;
            }
            td {
              z-index: 1;
              div {
                z-index: 1;
                border: none;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  &__spoilers {
    display: grid;
    gap: 10px;

    &-list {
      padding-left: 20px;

      li + li {
        margin-top: 6px;
      }
    }
  }

  &__banner {
    margin-bottom: 60px;

    @media screen and (min-width: 760px) {
      margin-bottom: 30px;
    }
  }
}
</style>
