<template>
  <div class="tour-place">
    <img :src="imageSrc" />
    <div
      class="tour-place-circle"
      :class="{
        'tour-place-circle--1': place === 1,
        'tour-place-circle--2': place === 2,
        'tour-place-circle--3': place === 3,
      }"
    >
      <svg v-if="place === 1" width="7" height="33" viewBox="0 0 7 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.71996 29.162C6.71996 30.2513 6.40463 31.0827 5.77396 31.656C5.17196 32.2293 4.41229 32.516 3.49496 32.516C2.57763 32.516 1.80363 32.2293 1.17296 31.656C0.570961 31.0827 0.269961 30.2513 0.269961 29.162V4.222C0.269961 3.13267 0.570961 2.30133 1.17296 1.728C1.80363 1.15466 2.57763 0.867998 3.49496 0.867998C4.41229 0.867998 5.17196 1.15466 5.77396 1.728C6.40463 2.30133 6.71996 3.13267 6.71996 4.222V29.162Z"
          fill="white"
        />
      </svg>
      <svg v-else-if="place === 2" width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.82992 29.162C6.82992 30.2513 6.51459 31.0827 5.88392 31.656C5.28192 32.2293 4.52226 32.516 3.60492 32.516C2.68759 32.516 1.91359 32.2293 1.28292 31.656C0.680922 31.0827 0.379922 30.2513 0.379922 29.162V4.222C0.379922 3.13267 0.680922 2.30133 1.28292 1.728C1.91359 1.15466 2.68759 0.867998 3.60492 0.867998C4.52226 0.867998 5.28192 1.15466 5.88392 1.728C6.51459 2.30133 6.82992 3.13267 6.82992 4.222V29.162ZM23.61 29.162C23.61 30.2513 23.2947 31.0827 22.664 31.656C22.062 32.2293 21.3023 32.516 20.385 32.516C19.4677 32.516 18.6937 32.2293 18.063 31.656C17.461 31.0827 17.16 30.2513 17.16 29.162V4.222C17.16 3.13267 17.461 2.30133 18.063 1.728C18.6937 1.15466 19.4677 0.867998 20.385 0.867998C21.3023 0.867998 22.062 1.15466 22.664 1.728C23.2947 2.30133 23.61 3.13267 23.61 4.222V29.162Z"
          fill="white"
        />
      </svg>
      <svg v-else-if="place === 3" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.73988 29.162C6.73988 30.2513 6.42455 31.0827 5.79388 31.656C5.19188 32.2293 4.43222 32.516 3.51488 32.516C2.59755 32.516 1.82355 32.2293 1.19288 31.656C0.590883 31.0827 0.289883 30.2513 0.289883 29.162V4.222C0.289883 3.13267 0.590883 2.30133 1.19288 1.728C1.82355 1.15466 2.59755 0.867998 3.51488 0.867998C4.43222 0.867998 5.19188 1.15466 5.79388 1.728C6.42455 2.30133 6.73988 3.13267 6.73988 4.222V29.162ZM19.22 29.162C19.22 30.2513 18.9046 31.0827 18.274 31.656C17.672 32.2293 16.9123 32.516 15.995 32.516C15.0776 32.516 14.3036 32.2293 13.673 31.656C13.071 31.0827 12.77 30.2513 12.77 29.162V4.222C12.77 3.13267 13.071 2.30133 13.673 1.728C14.3036 1.15466 15.0776 0.867998 15.995 0.867998C16.9123 0.867998 17.672 1.15466 18.274 1.728C18.9046 2.30133 19.22 3.13267 19.22 4.222V29.162ZM31.7 29.162C31.7 30.2513 31.3847 31.0827 30.754 31.656C30.152 32.2293 29.3924 32.516 28.475 32.516C27.5577 32.516 26.7837 32.2293 26.153 31.656C25.551 31.0827 25.25 30.2513 25.25 29.162V4.222C25.25 3.13267 25.551 2.30133 26.153 1.728C26.7837 1.15466 27.5577 0.867998 28.475 0.867998C29.3924 0.867998 30.152 1.15466 30.754 1.728C31.3847 2.30133 31.7 3.13267 31.7 4.222V29.162Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    place: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    placeText() {
      return Array(this.place).fill("I").join("");
    },
    imageSrc() {
      return new URL(`/assets/img/page/tournaments/place${this.place}.svg`, import.meta.url).href;
    },
  },
};
</script>

<style lang="scss">
.tour-place {
  position: relative;
  &-circle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    aspect-ratio: 1/1;
    border-radius: 50%;

    transform: translate(-50%, 15%);
    display: flex;
    align-items: center;
    justify-content: center;
    &--1 {
      background: radial-gradient(50% 50% at 50% 50%, #a2bdff 0%, #6b38ff 100%);
      box-shadow: 0px 35px 45px rgba(99, 69, 215, 0.45);
    }
    &--2 {
      background: radial-gradient(50% 50% at 50% 50%, #a8e46c 0%, #76ac41 100%);
      box-shadow: 0px 35px 45px #7ac134;
    }
    &--3 {
      background: radial-gradient(50% 50% at 50% 50%, #a2bdff 0%, #6b38ff 100%);
      box-shadow: 0px 35px 45px rgba(99, 69, 215, 0.45);
    }
  }
}
</style>
